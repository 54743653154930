export const BaseSelectBox = () => import('../../components/BaseSelectBox.vue' /* webpackChunkName: "components/base-select-box" */).then(c => wrapFunctional(c.default || c))
export const CGMData = () => import('../../components/CGMData.vue' /* webpackChunkName: "components/c-g-m-data" */).then(c => wrapFunctional(c.default || c))
export const ChatsComponent = () => import('../../components/ChatsComponent.vue' /* webpackChunkName: "components/chats-component" */).then(c => wrapFunctional(c.default || c))
export const Dropzone = () => import('../../components/Dropzone.vue' /* webpackChunkName: "components/dropzone" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/Page-header.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const ProviderSchedule = () => import('../../components/ProviderSchedule.vue' /* webpackChunkName: "components/provider-schedule" */).then(c => wrapFunctional(c.default || c))
export const RightSidebar = () => import('../../components/Right-sidebar.vue' /* webpackChunkName: "components/right-sidebar" */).then(c => wrapFunctional(c.default || c))
export const SideMenu = () => import('../../components/SideMenu.vue' /* webpackChunkName: "components/side-menu" */).then(c => wrapFunctional(c.default || c))
export const SideMenuPlayground = () => import('../../components/SideMenuPlayground.vue' /* webpackChunkName: "components/side-menu-playground" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const TableView = () => import('../../components/TableView.vue' /* webpackChunkName: "components/table-view" */).then(c => wrapFunctional(c.default || c))
export const Topbar = () => import('../../components/Topbar.vue' /* webpackChunkName: "components/topbar" */).then(c => wrapFunctional(c.default || c))
export const BulkScanningModal = () => import('../../components/bulkScanningModal.vue' /* webpackChunkName: "components/bulk-scanning-modal" */).then(c => wrapFunctional(c.default || c))
export const ConsentInformationModal = () => import('../../components/consentInformationModal.vue' /* webpackChunkName: "components/consent-information-modal" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../components/emptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/menu.js' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const RpmPatientDetails = () => import('../../components/rpmPatientDetails.vue' /* webpackChunkName: "components/rpm-patient-details" */).then(c => wrapFunctional(c.default || c))
export const DeleteBoardModal = () => import('../../components/DashboardModals/DeleteBoardModal.vue' /* webpackChunkName: "components/delete-board-modal" */).then(c => wrapFunctional(c.default || c))
export const DeleteMultipleBoardsModal = () => import('../../components/DashboardModals/DeleteMultipleBoardsModal.vue' /* webpackChunkName: "components/delete-multiple-boards-modal" */).then(c => wrapFunctional(c.default || c))
export const AddBoardInput = () => import('../../components/DashboardModals/addBoardInput.vue' /* webpackChunkName: "components/add-board-input" */).then(c => wrapFunctional(c.default || c))
export const AddBoardModal = () => import('../../components/DashboardModals/addBoardModal.vue' /* webpackChunkName: "components/add-board-modal" */).then(c => wrapFunctional(c.default || c))
export const AddCardModal = () => import('../../components/DashboardModals/addCardModal.vue' /* webpackChunkName: "components/add-card-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateCardStep = () => import('../../components/DashboardModals/createCardStep.vue' /* webpackChunkName: "components/create-card-step" */).then(c => wrapFunctional(c.default || c))
export const ManageTags = () => import('../../components/DashboardModals/manageTags.vue' /* webpackChunkName: "components/manage-tags" */).then(c => wrapFunctional(c.default || c))
export const AlertModal = () => import('../../components/admin/alertModal.vue' /* webpackChunkName: "components/alert-modal" */).then(c => wrapFunctional(c.default || c))
export const DeleteConfirmationModal = () => import('../../components/admin/deleteConfirmationModal.vue' /* webpackChunkName: "components/delete-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const OrganizationModal = () => import('../../components/admin/organizationModal.vue' /* webpackChunkName: "components/organization-modal" */).then(c => wrapFunctional(c.default || c))
export const QrCodeModal = () => import('../../components/admin/qrCodeModal.vue' /* webpackChunkName: "components/qr-code-modal" */).then(c => wrapFunctional(c.default || c))
export const RoleModal = () => import('../../components/admin/roleModal.vue' /* webpackChunkName: "components/role-modal" */).then(c => wrapFunctional(c.default || c))
export const RpmModal = () => import('../../components/admin/rpmModal.vue' /* webpackChunkName: "components/rpm-modal" */).then(c => wrapFunctional(c.default || c))
export const SpecialistModal = () => import('../../components/admin/specialistModal.vue' /* webpackChunkName: "components/specialist-modal" */).then(c => wrapFunctional(c.default || c))
export const AppointmentDetailsCard = () => import('../../components/appointments/appointmentDetailsCard.vue' /* webpackChunkName: "components/appointment-details-card" */).then(c => wrapFunctional(c.default || c))
export const AppointmentView = () => import('../../components/appointments/appointmentView.vue' /* webpackChunkName: "components/appointment-view" */).then(c => wrapFunctional(c.default || c))
export const ApexDonutChart = () => import('../../components/apexDonutChart/index.vue' /* webpackChunkName: "components/apex-donut-chart" */).then(c => wrapFunctional(c.default || c))
export const AssessmentList = () => import('../../components/assessments/assessmentList.vue' /* webpackChunkName: "components/assessment-list" */).then(c => wrapFunctional(c.default || c))
export const CreateAssessment = () => import('../../components/assessments/createAssessment.vue' /* webpackChunkName: "components/create-assessment" */).then(c => wrapFunctional(c.default || c))
export const BookingDetailsCard = () => import('../../components/bookings/bookingDetailsCard.vue' /* webpackChunkName: "components/booking-details-card" */).then(c => wrapFunctional(c.default || c))
export const BookingView = () => import('../../components/bookings/bookingView.vue' /* webpackChunkName: "components/booking-view" */).then(c => wrapFunctional(c.default || c))
export const TableContainer = () => import('../../components/amdiabetes/TableContainer.vue' /* webpackChunkName: "components/table-container" */).then(c => wrapFunctional(c.default || c))
export const DashboardDataContainer = () => import('../../components/amdiabetes/dashboardDataContainer.vue' /* webpackChunkName: "components/dashboard-data-container" */).then(c => wrapFunctional(c.default || c))
export const PatientTableView = () => import('../../components/amdiabetes/patientTableView.vue' /* webpackChunkName: "components/patient-table-view" */).then(c => wrapFunctional(c.default || c))
export const ReportsNotesContainer = () => import('../../components/amdiabetes/reportsNotesContainer.vue' /* webpackChunkName: "components/reports-notes-container" */).then(c => wrapFunctional(c.default || c))
export const RpmCcmChart = () => import('../../components/amdiabetes/rpmCcmChart.vue' /* webpackChunkName: "components/rpm-ccm-chart" */).then(c => wrapFunctional(c.default || c))
export const TableFilterComponent = () => import('../../components/amdiabetes/tableFilterComponent.vue' /* webpackChunkName: "components/table-filter-component" */).then(c => wrapFunctional(c.default || c))
export const BookAppointmentWizzard = () => import('../../components/cannahealrx/bookAppointmentWizzard.vue' /* webpackChunkName: "components/book-appointment-wizzard" */).then(c => wrapFunctional(c.default || c))
export const GoodCannaLogo = () => import('../../components/cannahealrx/goodCannaLogo.vue' /* webpackChunkName: "components/good-canna-logo" */).then(c => wrapFunctional(c.default || c))
export const NoAvailabilityCard = () => import('../../components/cannahealrx/noAvailabilityCard.vue' /* webpackChunkName: "components/no-availability-card" */).then(c => wrapFunctional(c.default || c))
export const CareNotes = () => import('../../components/care-notes/CareNotes.vue' /* webpackChunkName: "components/care-notes" */).then(c => wrapFunctional(c.default || c))
export const CareNotesBox = () => import('../../components/care-notes/CareNotesBox.vue' /* webpackChunkName: "components/care-notes-box" */).then(c => wrapFunctional(c.default || c))
export const CgmWidget = () => import('../../components/cgm/cgm-widget.vue' /* webpackChunkName: "components/cgm-widget" */).then(c => wrapFunctional(c.default || c))
export const CarePlanModal = () => import('../../components/care-plan/CarePlanModal.vue' /* webpackChunkName: "components/care-plan-modal" */).then(c => wrapFunctional(c.default || c))
export const CarePlanModalItem = () => import('../../components/care-plan/CarePlanModalItem.vue' /* webpackChunkName: "components/care-plan-modal-item" */).then(c => wrapFunctional(c.default || c))
export const CarePlanTables = () => import('../../components/care-plan/CarePlanTables.vue' /* webpackChunkName: "components/care-plan-tables" */).then(c => wrapFunctional(c.default || c))
export const CarePlanList = () => import('../../components/care-plan/carePlanList.vue' /* webpackChunkName: "components/care-plan-list" */).then(c => wrapFunctional(c.default || c))
export const ChatManager = () => import('../../components/chat/ChatManager.vue' /* webpackChunkName: "components/chat-manager" */).then(c => wrapFunctional(c.default || c))
export const ChatWindow = () => import('../../components/chat/ChatWindow.vue' /* webpackChunkName: "components/chat-window" */).then(c => wrapFunctional(c.default || c))
export const ImagePreviewModal = () => import('../../components/chat/ImagePreviewModal.vue' /* webpackChunkName: "components/image-preview-modal" */).then(c => wrapFunctional(c.default || c))
export const InboxWindow = () => import('../../components/chat/InboxWindow.vue' /* webpackChunkName: "components/inbox-window" */).then(c => wrapFunctional(c.default || c))
export const MessageDeleteConfirmation = () => import('../../components/chat/MessageDeleteConfirmation.vue' /* webpackChunkName: "components/message-delete-confirmation" */).then(c => wrapFunctional(c.default || c))
export const MinimizedItemsWindow = () => import('../../components/chat/MinimizedItemsWindow.vue' /* webpackChunkName: "components/minimized-items-window" */).then(c => wrapFunctional(c.default || c))
export const VianovaAudioRecorder = () => import('../../components/chat/VianovaAudioRecorder.vue' /* webpackChunkName: "components/vianova-audio-recorder" */).then(c => wrapFunctional(c.default || c))
export const CareNotesContainer = () => import('../../components/communications/care-notes-container.vue' /* webpackChunkName: "components/care-notes-container" */).then(c => wrapFunctional(c.default || c))
export const CommunicationMedic = () => import('../../components/communications/communication-medic.vue' /* webpackChunkName: "components/communication-medic" */).then(c => wrapFunctional(c.default || c))
export const Trends = () => import('../../components/communications/trends.vue' /* webpackChunkName: "components/trends" */).then(c => wrapFunctional(c.default || c))
export const Vitals = () => import('../../components/communications/vitals.vue' /* webpackChunkName: "components/vitals" */).then(c => wrapFunctional(c.default || c))
export const AlertTextComponent = () => import('../../components/chat-components/AlertTextComponent.vue' /* webpackChunkName: "components/alert-text-component" */).then(c => wrapFunctional(c.default || c))
export const AudioComponent = () => import('../../components/chat-components/AudioComponent.vue' /* webpackChunkName: "components/audio-component" */).then(c => wrapFunctional(c.default || c))
export const FileComponent = () => import('../../components/chat-components/FileComponent.vue' /* webpackChunkName: "components/file-component" */).then(c => wrapFunctional(c.default || c))
export const ImageComponent = () => import('../../components/chat-components/ImageComponent.vue' /* webpackChunkName: "components/image-component" */).then(c => wrapFunctional(c.default || c))
export const VideoComponent = () => import('../../components/chat-components/VideoComponent.vue' /* webpackChunkName: "components/video-component" */).then(c => wrapFunctional(c.default || c))
export const CptCode = () => import('../../components/dashboard/CptCode.vue' /* webpackChunkName: "components/cpt-code" */).then(c => wrapFunctional(c.default || c))
export const ActivityCard = () => import('../../components/dashboard/activityCard.vue' /* webpackChunkName: "components/activity-card" */).then(c => wrapFunctional(c.default || c))
export const AlertsCard = () => import('../../components/dashboard/alertsCard.vue' /* webpackChunkName: "components/alerts-card" */).then(c => wrapFunctional(c.default || c))
export const BillingInfoCard = () => import('../../components/dashboard/billingInfoCard.vue' /* webpackChunkName: "components/billing-info-card" */).then(c => wrapFunctional(c.default || c))
export const BillingInformationCard = () => import('../../components/dashboard/billingInformationCard.vue' /* webpackChunkName: "components/billing-information-card" */).then(c => wrapFunctional(c.default || c))
export const BillingProgressCard = () => import('../../components/dashboard/billingProgressCard.vue' /* webpackChunkName: "components/billing-progress-card" */).then(c => wrapFunctional(c.default || c))
export const CcmSectionContainer = () => import('../../components/dashboard/ccmSectionContainer.vue' /* webpackChunkName: "components/ccm-section-container" */).then(c => wrapFunctional(c.default || c))
export const CgmEnrollmentComponent = () => import('../../components/dashboard/cgmEnrollmentComponent.vue' /* webpackChunkName: "components/cgm-enrollment-component" */).then(c => wrapFunctional(c.default || c))
export const GeneralCard = () => import('../../components/dashboard/generalCard.vue' /* webpackChunkName: "components/general-card" */).then(c => wrapFunctional(c.default || c))
export const PatientActivityComponent = () => import('../../components/dashboard/patientActivityComponent.vue' /* webpackChunkName: "components/patient-activity-component" */).then(c => wrapFunctional(c.default || c))
export const ProgressComponent = () => import('../../components/dashboard/progressComponent.vue' /* webpackChunkName: "components/progress-component" */).then(c => wrapFunctional(c.default || c))
export const RadialChart = () => import('../../components/dashboard/radialChart.vue' /* webpackChunkName: "components/radial-chart" */).then(c => wrapFunctional(c.default || c))
export const RadialDashboardChart = () => import('../../components/dashboard/radialDashboardChart.vue' /* webpackChunkName: "components/radial-dashboard-chart" */).then(c => wrapFunctional(c.default || c))
export const RpmSectionContainer = () => import('../../components/dashboard/rpmSectionContainer.vue' /* webpackChunkName: "components/rpm-section-container" */).then(c => wrapFunctional(c.default || c))
export const RpmStatsComponent = () => import('../../components/dashboard/rpmStatsComponent.vue' /* webpackChunkName: "components/rpm-stats-component" */).then(c => wrapFunctional(c.default || c))
export const RtmSectionContainer = () => import('../../components/dashboard/rtmSectionContainer.vue' /* webpackChunkName: "components/rtm-section-container" */).then(c => wrapFunctional(c.default || c))
export const VentiChartCard = () => import('../../components/dashboard/ventiChartCard.vue' /* webpackChunkName: "components/venti-chart-card" */).then(c => wrapFunctional(c.default || c))
export const VitalCardComponent = () => import('../../components/dashboard/vitalCardComponent.vue' /* webpackChunkName: "components/vital-card-component" */).then(c => wrapFunctional(c.default || c))
export const DentalMeasurement = () => import('../../components/dental/DentalMeasurement.vue' /* webpackChunkName: "components/dental-measurement" */).then(c => wrapFunctional(c.default || c))
export const DentalMeasurementTable = () => import('../../components/dental/DentalMeasurementTable.vue' /* webpackChunkName: "components/dental-measurement-table" */).then(c => wrapFunctional(c.default || c))
export const DentalTaskModal = () => import('../../components/dental/DentalTaskModal.vue' /* webpackChunkName: "components/dental-task-modal" */).then(c => wrapFunctional(c.default || c))
export const DeviceModal = () => import('../../components/devices/deviceModal.vue' /* webpackChunkName: "components/device-modal" */).then(c => wrapFunctional(c.default || c))
export const DocumentHistory = () => import('../../components/documents/DocumentHistory.vue' /* webpackChunkName: "components/document-history" */).then(c => wrapFunctional(c.default || c))
export const DocumentSelector = () => import('../../components/documents/DocumentSelector.vue' /* webpackChunkName: "components/document-selector" */).then(c => wrapFunctional(c.default || c))
export const PatientFilter = () => import('../../components/documents/PatientFilter.vue' /* webpackChunkName: "components/patient-filter" */).then(c => wrapFunctional(c.default || c))
export const PatientsTable = () => import('../../components/documents/PatientsTable.vue' /* webpackChunkName: "components/patients-table" */).then(c => wrapFunctional(c.default || c))
export const SharedDocumentsList = () => import('../../components/documents/SharedDocumentsList.vue' /* webpackChunkName: "components/shared-documents-list" */).then(c => wrapFunctional(c.default || c))
export const DocumentView = () => import('../../components/documents/documentView.vue' /* webpackChunkName: "components/document-view" */).then(c => wrapFunctional(c.default || c))
export const FitbitMeasurementChart = () => import('../../components/fitbit/fitbitMeasurementChart.vue' /* webpackChunkName: "components/fitbit-measurement-chart" */).then(c => wrapFunctional(c.default || c))
export const FitbitMeasurementContainer = () => import('../../components/fitbit/fitbitMeasurementContainer.vue' /* webpackChunkName: "components/fitbit-measurement-container" */).then(c => wrapFunctional(c.default || c))
export const FitbitStatusBadge = () => import('../../components/fitbit/fitbitStatusBadge.vue' /* webpackChunkName: "components/fitbit-status-badge" */).then(c => wrapFunctional(c.default || c))
export const ExitChatModal = () => import('../../components/general_chat/exitChatModal.vue' /* webpackChunkName: "components/exit-chat-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralChatBox = () => import('../../components/general_chat/generalChatBox.vue' /* webpackChunkName: "components/general-chat-box" */).then(c => wrapFunctional(c.default || c))
export const GeneralChatEmptyState = () => import('../../components/general_chat/generalChatEmptyState.vue' /* webpackChunkName: "components/general-chat-empty-state" */).then(c => wrapFunctional(c.default || c))
export const GeneralChatMediaModal = () => import('../../components/general_chat/generalChatMediaModal.vue' /* webpackChunkName: "components/general-chat-media-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralChatMessage = () => import('../../components/general_chat/generalChatMessage.vue' /* webpackChunkName: "components/general-chat-message" */).then(c => wrapFunctional(c.default || c))
export const GeneralChatView = () => import('../../components/general_chat/generalChatView.vue' /* webpackChunkName: "components/general-chat-view" */).then(c => wrapFunctional(c.default || c))
export const InboxEntryContainer = () => import('../../components/general_chat/inboxEntryContainer.vue' /* webpackChunkName: "components/inbox-entry-container" */).then(c => wrapFunctional(c.default || c))
export const NewChatModal = () => import('../../components/general_chat/newChatModal.vue' /* webpackChunkName: "components/new-chat-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthSuccess = () => import('../../components/icons/authSuccess.vue' /* webpackChunkName: "components/auth-success" */).then(c => wrapFunctional(c.default || c))
export const PinnedIcon = () => import('../../components/icons/pinned-icon.vue' /* webpackChunkName: "components/pinned-icon" */).then(c => wrapFunctional(c.default || c))
export const SelectAction = () => import('../../components/icons/select-action.vue' /* webpackChunkName: "components/select-action" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = () => import('../../components/icons/svg-icon.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const TwoFactorIcon = () => import('../../components/icons/twoFactorIcon.vue' /* webpackChunkName: "components/two-factor-icon" */).then(c => wrapFunctional(c.default || c))
export const MagicSidebar = () => import('../../components/magic/magic-sidebar.vue' /* webpackChunkName: "components/magic-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MagicStats = () => import('../../components/magic/magic-stats.vue' /* webpackChunkName: "components/magic-stats" */).then(c => wrapFunctional(c.default || c))
export const MagicTopbar = () => import('../../components/magic/magic-topbar.vue' /* webpackChunkName: "components/magic-topbar" */).then(c => wrapFunctional(c.default || c))
export const AcceptApplicantModal = () => import('../../components/halee/AcceptApplicantModal.vue' /* webpackChunkName: "components/accept-applicant-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateApplicantModal = () => import('../../components/halee/CreateApplicantModal.vue' /* webpackChunkName: "components/create-applicant-modal" */).then(c => wrapFunctional(c.default || c))
export const RejectApplicantModal = () => import('../../components/halee/RejectApplicantModal.vue' /* webpackChunkName: "components/reject-applicant-modal" */).then(c => wrapFunctional(c.default || c))
export const ViewApplicantDetailsModal = () => import('../../components/halee/ViewApplicantDetailsModal.vue' /* webpackChunkName: "components/view-applicant-details-modal" */).then(c => wrapFunctional(c.default || c))
export const ViewApplicantDetailsModalOld = () => import('../../components/halee/ViewApplicantDetailsModalOld.vue' /* webpackChunkName: "components/view-applicant-details-modal-old" */).then(c => wrapFunctional(c.default || c))
export const HaleeTableFilter = () => import('../../components/halee/haleeTableFilter.vue' /* webpackChunkName: "components/halee-table-filter" */).then(c => wrapFunctional(c.default || c))
export const HomeGroupComponent = () => import('../../components/halee/homeGroupComponent.vue' /* webpackChunkName: "components/home-group-component" */).then(c => wrapFunctional(c.default || c))
export const Medications = () => import('../../components/medications/Medications.vue' /* webpackChunkName: "components/medications" */).then(c => wrapFunctional(c.default || c))
export const MedicsModal = () => import('../../components/medications/MedicsModal.vue' /* webpackChunkName: "components/medics-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalMedic = () => import('../../components/medications/ModalMedic.vue' /* webpackChunkName: "components/modal-medic" */).then(c => wrapFunctional(c.default || c))
export const BadgeModal = () => import('../../components/library/BadgeModal.vue' /* webpackChunkName: "components/badge-modal" */).then(c => wrapFunctional(c.default || c))
export const Book = () => import('../../components/library/Book.vue' /* webpackChunkName: "components/book" */).then(c => wrapFunctional(c.default || c))
export const BookModal = () => import('../../components/library/BookModal.vue' /* webpackChunkName: "components/book-modal" */).then(c => wrapFunctional(c.default || c))
export const RcbModal = () => import('../../components/library/RcbModal.vue' /* webpackChunkName: "components/rcb-modal" */).then(c => wrapFunctional(c.default || c))
export const NotesContainer = () => import('../../components/notes/notesContainer.vue' /* webpackChunkName: "components/notes-container" */).then(c => wrapFunctional(c.default || c))
export const NotesFiltersComponent = () => import('../../components/notes/notesFiltersComponent.vue' /* webpackChunkName: "components/notes-filters-component" */).then(c => wrapFunctional(c.default || c))
export const NotificationModal = () => import('../../components/notifications/NotificationModal.vue' /* webpackChunkName: "components/notification-modal" */).then(c => wrapFunctional(c.default || c))
export const AssessmentStepsHeader = () => import('../../components/oasis/AssessmentStepsHeader.vue' /* webpackChunkName: "components/assessment-steps-header" */).then(c => wrapFunctional(c.default || c))
export const OasisTableContainer = () => import('../../components/oasis/OasisTableContainer.vue' /* webpackChunkName: "components/oasis-table-container" */).then(c => wrapFunctional(c.default || c))
export const OasisTableFilter = () => import('../../components/oasis/OasisTableFilter.vue' /* webpackChunkName: "components/oasis-table-filter" */).then(c => wrapFunctional(c.default || c))
export const OasisNotesContainer = () => import('../../components/oasis/oasisNotesContainer.vue' /* webpackChunkName: "components/oasis-notes-container" */).then(c => wrapFunctional(c.default || c))
export const OasisPatientDetails = () => import('../../components/oasis/oasisPatientDetails.vue' /* webpackChunkName: "components/oasis-patient-details" */).then(c => wrapFunctional(c.default || c))
export const OasisTableView = () => import('../../components/oasis/oasisTableView.vue' /* webpackChunkName: "components/oasis-table-view" */).then(c => wrapFunctional(c.default || c))
export const CanvasCircularProgress = () => import('../../components/overview/CanvasCircularProgress.vue' /* webpackChunkName: "components/canvas-circular-progress" */).then(c => wrapFunctional(c.default || c))
export const DentalTasksCompletion = () => import('../../components/overview/DentalTasksCompletion.vue' /* webpackChunkName: "components/dental-tasks-completion" */).then(c => wrapFunctional(c.default || c))
export const DentalTrendingData = () => import('../../components/overview/DentalTrendingData.vue' /* webpackChunkName: "components/dental-trending-data" */).then(c => wrapFunctional(c.default || c))
export const OxygenSaturation = () => import('../../components/overview/OxygenSaturation.vue' /* webpackChunkName: "components/oxygen-saturation" */).then(c => wrapFunctional(c.default || c))
export const PatientCard = () => import('../../components/overview/PatientCard.vue' /* webpackChunkName: "components/patient-card" */).then(c => wrapFunctional(c.default || c))
export const PatientCardDental = () => import('../../components/overview/PatientCardDental.vue' /* webpackChunkName: "components/patient-card-dental" */).then(c => wrapFunctional(c.default || c))
export const TrendingData = () => import('../../components/overview/TrendingData.vue' /* webpackChunkName: "components/trending-data" */).then(c => wrapFunctional(c.default || c))
export const DoNotDisturbBadge = () => import('../../components/overview/doNotDisturbBadge.vue' /* webpackChunkName: "components/do-not-disturb-badge" */).then(c => wrapFunctional(c.default || c))
export const DoNotDisturbModal = () => import('../../components/overview/doNotDisturbModal.vue' /* webpackChunkName: "components/do-not-disturb-modal" */).then(c => wrapFunctional(c.default || c))
export const VacationAcknowledgeModal = () => import('../../components/overview/vacationAcknowledgeModal.vue' /* webpackChunkName: "components/vacation-acknowledge-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterAppearanceView = () => import('../../components/patientFilters/filterAppearanceView.vue' /* webpackChunkName: "components/filter-appearance-view" */).then(c => wrapFunctional(c.default || c))
export const FilterFieldSelect = () => import('../../components/patientFilters/filterFieldSelect.vue' /* webpackChunkName: "components/filter-field-select" */).then(c => wrapFunctional(c.default || c))
export const FilterGroups = () => import('../../components/patientFilters/filterGroups.vue' /* webpackChunkName: "components/filter-groups" */).then(c => wrapFunctional(c.default || c))
export const FilterTypeSelect = () => import('../../components/patientFilters/filterTypeSelect.vue' /* webpackChunkName: "components/filter-type-select" */).then(c => wrapFunctional(c.default || c))
export const PatientFilters = () => import('../../components/patientFilters/patient-filters.js' /* webpackChunkName: "components/patient-filters" */).then(c => wrapFunctional(c.default || c))
export const PatientFilterModal = () => import('../../components/patientFilters/patientFilterModal.vue' /* webpackChunkName: "components/patient-filter-modal" */).then(c => wrapFunctional(c.default || c))
export const PlanBuilderModal = () => import('../../components/plan-builder/PlanBuilderModal.vue' /* webpackChunkName: "components/plan-builder-modal" */).then(c => wrapFunctional(c.default || c))
export const CustomizeColumns = () => import('../../components/quick-view/CustomizeColumns.vue' /* webpackChunkName: "components/customize-columns" */).then(c => wrapFunctional(c.default || c))
export const QuickViewModal = () => import('../../components/quick-view/QuickViewModal.vue' /* webpackChunkName: "components/quick-view-modal" */).then(c => wrapFunctional(c.default || c))
export const RcbResponseList = () => import('../../components/rcbResponses/rcbResponseList.vue' /* webpackChunkName: "components/rcb-response-list" */).then(c => wrapFunctional(c.default || c))
export const ReportsCcmAssessmentsContainer = () => import('../../components/reports/reportsCcmAssessmentsContainer.vue' /* webpackChunkName: "components/reports-ccm-assessments-container" */).then(c => wrapFunctional(c.default || c))
export const AddRemovePointsModal = () => import('../../components/rewards/AddRemovePointsModal.vue' /* webpackChunkName: "components/add-remove-points-modal" */).then(c => wrapFunctional(c.default || c))
export const PatientPoints = () => import('../../components/rewards/PatientPoints.vue' /* webpackChunkName: "components/patient-points" */).then(c => wrapFunctional(c.default || c))
export const RewardCard = () => import('../../components/rewards/RewardCard.vue' /* webpackChunkName: "components/reward-card" */).then(c => wrapFunctional(c.default || c))
export const RewardCardAlternative = () => import('../../components/rewards/RewardCardAlternative.vue' /* webpackChunkName: "components/reward-card-alternative" */).then(c => wrapFunctional(c.default || c))
export const RewardsListView = () => import('../../components/rewards/RewardsListView.vue' /* webpackChunkName: "components/rewards-list-view" */).then(c => wrapFunctional(c.default || c))
export const RewardsTab = () => import('../../components/rewards/RewardsTab.vue' /* webpackChunkName: "components/rewards-tab" */).then(c => wrapFunctional(c.default || c))
export const BPClinicialDataContainer = () => import('../../components/rpm-reports/BPClinicialDataContainer.vue' /* webpackChunkName: "components/b-p-clinicial-data-container" */).then(c => wrapFunctional(c.default || c))
export const BPHeartRateChart = () => import('../../components/rpm-reports/BPHeartRateChart.vue' /* webpackChunkName: "components/b-p-heart-rate-chart" */).then(c => wrapFunctional(c.default || c))
export const ClinicalReportsContainer = () => import('../../components/rpm-reports/clinicalReportsContainer.vue' /* webpackChunkName: "components/clinical-reports-container" */).then(c => wrapFunctional(c.default || c))
export const NoTypeEmptyState = () => import('../../components/rpm-reports/noTypeEmptyState.vue' /* webpackChunkName: "components/no-type-empty-state" */).then(c => wrapFunctional(c.default || c))
export const ReportPatientInfo = () => import('../../components/rpm-reports/reportPatientInfo.vue' /* webpackChunkName: "components/report-patient-info" */).then(c => wrapFunctional(c.default || c))
export const ReportTrackersTable = () => import('../../components/rpm-reports/reportTrackersTable.vue' /* webpackChunkName: "components/report-trackers-table" */).then(c => wrapFunctional(c.default || c))
export const ReportTypeChartContainer = () => import('../../components/rpm-reports/reportTypeChartContainer.vue' /* webpackChunkName: "components/report-type-chart-container" */).then(c => wrapFunctional(c.default || c))
export const ReportsPdfHeader = () => import('../../components/rpm-reports/reportsPdfHeader.vue' /* webpackChunkName: "components/reports-pdf-header" */).then(c => wrapFunctional(c.default || c))
export const ReportsVitalChart = () => import('../../components/rpm-reports/reportsVitalChart.vue' /* webpackChunkName: "components/reports-vital-chart" */).then(c => wrapFunctional(c.default || c))
export const RpmReportsContainer = () => import('../../components/rpm-reports/rpmReportsContainer.vue' /* webpackChunkName: "components/rpm-reports-container" */).then(c => wrapFunctional(c.default || c))
export const RpmReportsEmptyState = () => import('../../components/rpm-reports/rpmReportsEmptyState.vue' /* webpackChunkName: "components/rpm-reports-empty-state" */).then(c => wrapFunctional(c.default || c))
export const RpmReportsFilters = () => import('../../components/rpm-reports/rpmReportsFilters.vue' /* webpackChunkName: "components/rpm-reports-filters" */).then(c => wrapFunctional(c.default || c))
export const RtmReportsContainer = () => import('../../components/rpm-reports/rtmReportsContainer.vue' /* webpackChunkName: "components/rtm-reports-container" */).then(c => wrapFunctional(c.default || c))
export const VitalsClinicalDataContainer = () => import('../../components/rpm-reports/vitalsClinicalDataContainer.vue' /* webpackChunkName: "components/vitals-clinical-data-container" */).then(c => wrapFunctional(c.default || c))
export const SyracuseTableFilter = () => import('../../components/syracuse/syracuseTableFilter.vue' /* webpackChunkName: "components/syracuse-table-filter" */).then(c => wrapFunctional(c.default || c))
export const CallLogCard = () => import('../../components/team_monitoring/callLogCard.vue' /* webpackChunkName: "components/call-log-card" */).then(c => wrapFunctional(c.default || c))
export const CallTimerCard = () => import('../../components/team_monitoring/callTimerCard.vue' /* webpackChunkName: "components/call-timer-card" */).then(c => wrapFunctional(c.default || c))
export const CallsProgressCard = () => import('../../components/team_monitoring/callsProgressCard.vue' /* webpackChunkName: "components/calls-progress-card" */).then(c => wrapFunctional(c.default || c))
export const CoordinatorTableContainer = () => import('../../components/team_monitoring/coordinatorTableContainer.vue' /* webpackChunkName: "components/coordinator-table-container" */).then(c => wrapFunctional(c.default || c))
export const DatePickerButtons = () => import('../../components/team_monitoring/datePickerButtons.vue' /* webpackChunkName: "components/date-picker-buttons" */).then(c => wrapFunctional(c.default || c))
export const IcdCodeCards = () => import('../../components/team_monitoring/icdCodeCards.vue' /* webpackChunkName: "components/icd-code-cards" */).then(c => wrapFunctional(c.default || c))
export const LargeMonitoringTable = () => import('../../components/team_monitoring/largeMonitoringTable.vue' /* webpackChunkName: "components/large-monitoring-table" */).then(c => wrapFunctional(c.default || c))
export const Tile = () => import('../../components/tiles/Tile.vue' /* webpackChunkName: "components/tile" */).then(c => wrapFunctional(c.default || c))
export const Tiles = () => import('../../components/tiles/Tiles.vue' /* webpackChunkName: "components/tiles" */).then(c => wrapFunctional(c.default || c))
export const TimerEntryContainer = () => import('../../components/timers/timerEntryContainer.vue' /* webpackChunkName: "components/timer-entry-container" */).then(c => wrapFunctional(c.default || c))
export const TimerOverview = () => import('../../components/timers/timerOverview.vue' /* webpackChunkName: "components/timer-overview" */).then(c => wrapFunctional(c.default || c))
export const TrackerEntry = () => import('../../components/timers/trackerEntry.vue' /* webpackChunkName: "components/tracker-entry" */).then(c => wrapFunctional(c.default || c))
export const UserTabs = () => import('../../components/user-tabs/UserTabs.vue' /* webpackChunkName: "components/user-tabs" */).then(c => wrapFunctional(c.default || c))
export const VitalsMeasurement = () => import('../../components/vitals-measurement/VitalsMeasurement.vue' /* webpackChunkName: "components/vitals-measurement" */).then(c => wrapFunctional(c.default || c))
export const Activity = () => import('../../components/widgets/Activity.vue' /* webpackChunkName: "components/activity" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/widgets/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const ProgressTask = () => import('../../components/widgets/Progress-task.vue' /* webpackChunkName: "components/progress-task" */).then(c => wrapFunctional(c.default || c))
export const RemindersBox = () => import('../../components/widgets/RemindersBox.vue' /* webpackChunkName: "components/reminders-box" */).then(c => wrapFunctional(c.default || c))
export const SalesAnalytics = () => import('../../components/widgets/Sales-analytics.vue' /* webpackChunkName: "components/sales-analytics" */).then(c => wrapFunctional(c.default || c))
export const SimpleCard = () => import('../../components/widgets/Simple-card.vue' /* webpackChunkName: "components/simple-card" */).then(c => wrapFunctional(c.default || c))
export const SocialSource = () => import('../../components/widgets/Social-source.vue' /* webpackChunkName: "components/social-source" */).then(c => wrapFunctional(c.default || c))
export const Stats = () => import('../../components/widgets/Stats.vue' /* webpackChunkName: "components/stats" */).then(c => wrapFunctional(c.default || c))
export const TableCard = () => import('../../components/widgets/Table-card.vue' /* webpackChunkName: "components/table-card" */).then(c => wrapFunctional(c.default || c))
export const TopUsers = () => import('../../components/widgets/Top-users.vue' /* webpackChunkName: "components/top-users" */).then(c => wrapFunctional(c.default || c))
export const VitalsInfo = () => import('../../components/widgets/Vitals-info.vue' /* webpackChunkName: "components/vitals-info" */).then(c => wrapFunctional(c.default || c))
export const Alerts = () => import('../../components/widgets/alerts.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const EditSharedDocumentModal = () => import('../../components/admin/documents/editSharedDocumentModal.vue' /* webpackChunkName: "components/edit-shared-document-modal" */).then(c => wrapFunctional(c.default || c))
export const GroupComponent = () => import('../../components/admin/groups/group-component.vue' /* webpackChunkName: "components/group-component" */).then(c => wrapFunctional(c.default || c))
export const EditRewardModal = () => import('../../components/admin/rewards/editRewardModal.vue' /* webpackChunkName: "components/edit-reward-modal" */).then(c => wrapFunctional(c.default || c))
export const AppointmentConfirmationModal = () => import('../../components/appointments/modals/appointmentConfirmationModal.vue' /* webpackChunkName: "components/appointment-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const AppointmentDetailsModal = () => import('../../components/appointments/modals/appointmentDetailsModal.vue' /* webpackChunkName: "components/appointment-details-modal" */).then(c => wrapFunctional(c.default || c))
export const AppointmentEmptyStateSVG = () => import('../../components/appointments/modals/appointmentEmptyStateSVG.vue' /* webpackChunkName: "components/appointment-empty-state-s-v-g" */).then(c => wrapFunctional(c.default || c))
export const AppointmentStatusModal = () => import('../../components/appointments/modals/appointmentStatusModal.vue' /* webpackChunkName: "components/appointment-status-modal" */).then(c => wrapFunctional(c.default || c))
export const PingModal = () => import('../../components/appointments/modals/pingModal.vue' /* webpackChunkName: "components/ping-modal" */).then(c => wrapFunctional(c.default || c))
export const ViewFutureAppointmentsModal = () => import('../../components/appointments/modals/viewFutureAppointmentsModal.vue' /* webpackChunkName: "components/view-future-appointments-modal" */).then(c => wrapFunctional(c.default || c))
export const BookingDetailsModal = () => import('../../components/bookings/modals/bookingDetailsModal.vue' /* webpackChunkName: "components/booking-details-modal" */).then(c => wrapFunctional(c.default || c))
export const BookingEmptyStateSVG = () => import('../../components/bookings/modals/bookingEmptyStateSVG.vue' /* webpackChunkName: "components/booking-empty-state-s-v-g" */).then(c => wrapFunctional(c.default || c))
export const BookingStatusModal = () => import('../../components/bookings/modals/bookingStatusModal.vue' /* webpackChunkName: "components/booking-status-modal" */).then(c => wrapFunctional(c.default || c))
export const EditBookingModal = () => import('../../components/bookings/modals/editBookingModal.vue' /* webpackChunkName: "components/edit-booking-modal" */).then(c => wrapFunctional(c.default || c))
export const AddEncounterModal = () => import('../../components/amdiabetes/modals/addEncounterModal.vue' /* webpackChunkName: "components/add-encounter-modal" */).then(c => wrapFunctional(c.default || c))
export const AssignDentalPatientModal = () => import('../../components/amdiabetes/modals/assignDentalPatientModal.vue' /* webpackChunkName: "components/assign-dental-patient-modal" */).then(c => wrapFunctional(c.default || c))
export const AssignPatientModal = () => import('../../components/amdiabetes/modals/assignPatientModal.vue' /* webpackChunkName: "components/assign-patient-modal" */).then(c => wrapFunctional(c.default || c))
export const BillingNoteModal = () => import('../../components/amdiabetes/modals/billingNoteModal.vue' /* webpackChunkName: "components/billing-note-modal" */).then(c => wrapFunctional(c.default || c))
export const BulkUploadModal = () => import('../../components/amdiabetes/modals/bulkUploadModal.vue' /* webpackChunkName: "components/bulk-upload-modal" */).then(c => wrapFunctional(c.default || c))
export const CustomizeHeaderColumns = () => import('../../components/amdiabetes/modals/customizeHeaderColumns.vue' /* webpackChunkName: "components/customize-header-columns" */).then(c => wrapFunctional(c.default || c))
export const EditPatientDetailsModal = () => import('../../components/amdiabetes/modals/editPatientDetailsModal.vue' /* webpackChunkName: "components/edit-patient-details-modal" */).then(c => wrapFunctional(c.default || c))
export const LastNoteModal = () => import('../../components/amdiabetes/modals/lastNoteModal.vue' /* webpackChunkName: "components/last-note-modal" */).then(c => wrapFunctional(c.default || c))
export const OnboardDentalPatientModal = () => import('../../components/amdiabetes/modals/onboardDentalPatientModal.vue' /* webpackChunkName: "components/onboard-dental-patient-modal" */).then(c => wrapFunctional(c.default || c))
export const OnboardPatientModal = () => import('../../components/amdiabetes/modals/onboardPatientModal.vue' /* webpackChunkName: "components/onboard-patient-modal" */).then(c => wrapFunctional(c.default || c))
export const SmsModal = () => import('../../components/amdiabetes/modals/smsModal.vue' /* webpackChunkName: "components/sms-modal" */).then(c => wrapFunctional(c.default || c))
export const BookingTimer = () => import('../../components/cannahealrx/bookAppointmentSections/BookingTimer.vue' /* webpackChunkName: "components/booking-timer" */).then(c => wrapFunctional(c.default || c))
export const BookingSteps = () => import('../../components/cannahealrx/bookAppointmentSections/bookingSteps.vue' /* webpackChunkName: "components/booking-steps" */).then(c => wrapFunctional(c.default || c))
export const Cannalogo = () => import('../../components/cannahealrx/bookAppointmentSections/cannalogo.vue' /* webpackChunkName: "components/cannalogo" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSection = () => import('../../components/cannahealrx/bookAppointmentSections/checkoutSection.vue' /* webpackChunkName: "components/checkout-section" */).then(c => wrapFunctional(c.default || c))
export const DetailsSection = () => import('../../components/cannahealrx/bookAppointmentSections/detailsSection.vue' /* webpackChunkName: "components/details-section" */).then(c => wrapFunctional(c.default || c))
export const DoneSection = () => import('../../components/cannahealrx/bookAppointmentSections/doneSection.vue' /* webpackChunkName: "components/done-section" */).then(c => wrapFunctional(c.default || c))
export const IntakeFormHeader = () => import('../../components/cannahealrx/bookAppointmentSections/intakeFormHeader.vue' /* webpackChunkName: "components/intake-form-header" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection = () => import('../../components/cannahealrx/bookAppointmentSections/servicesSection.vue' /* webpackChunkName: "components/services-section" */).then(c => wrapFunctional(c.default || c))
export const TimeSection = () => import('../../components/cannahealrx/bookAppointmentSections/timeSection.vue' /* webpackChunkName: "components/time-section" */).then(c => wrapFunctional(c.default || c))
export const AdminDashboardView = () => import('../../components/cannahealrx/dashboard/adminDashboardView.vue' /* webpackChunkName: "components/admin-dashboard-view" */).then(c => wrapFunctional(c.default || c))
export const DispensaryBreakdown = () => import('../../components/cannahealrx/dashboard/dispensaryBreakdown.vue' /* webpackChunkName: "components/dispensary-breakdown" */).then(c => wrapFunctional(c.default || c))
export const ProviderDashboardView = () => import('../../components/cannahealrx/dashboard/providerDashboardView.vue' /* webpackChunkName: "components/provider-dashboard-view" */).then(c => wrapFunctional(c.default || c))
export const ProviderStatChart = () => import('../../components/cannahealrx/dashboard/providerStatChart.vue' /* webpackChunkName: "components/provider-stat-chart" */).then(c => wrapFunctional(c.default || c))
export const StateBreakdownComponent = () => import('../../components/cannahealrx/dashboard/stateBreakdownComponent.vue' /* webpackChunkName: "components/state-breakdown-component" */).then(c => wrapFunctional(c.default || c))
export const StatusBreakdown = () => import('../../components/cannahealrx/dashboard/statusBreakdown.vue' /* webpackChunkName: "components/status-breakdown" */).then(c => wrapFunctional(c.default || c))
export const AddCannaNoteModal = () => import('../../components/cannahealrx/modals/addCannaNoteModal.vue' /* webpackChunkName: "components/add-canna-note-modal" */).then(c => wrapFunctional(c.default || c))
export const AppointmentConfirm = () => import('../../components/cannahealrx/modals/appointmentConfirm.vue' /* webpackChunkName: "components/appointment-confirm" */).then(c => wrapFunctional(c.default || c))
export const CannaEditPatientModal = () => import('../../components/cannahealrx/modals/cannaEditPatientModal.vue' /* webpackChunkName: "components/canna-edit-patient-modal" */).then(c => wrapFunctional(c.default || c))
export const CannaFaxFormModal = () => import('../../components/cannahealrx/modals/cannaFaxFormModal.vue' /* webpackChunkName: "components/canna-fax-form-modal" */).then(c => wrapFunctional(c.default || c))
export const CannaFileModal = () => import('../../components/cannahealrx/modals/cannaFileModal.vue' /* webpackChunkName: "components/canna-file-modal" */).then(c => wrapFunctional(c.default || c))
export const CompleteProfileModal = () => import('../../components/cannahealrx/modals/completeProfileModal.vue' /* webpackChunkName: "components/complete-profile-modal" */).then(c => wrapFunctional(c.default || c))
export const DisableTwoAuthModal = () => import('../../components/cannahealrx/modals/disableTwoAuthModal.vue' /* webpackChunkName: "components/disable-two-auth-modal" */).then(c => wrapFunctional(c.default || c))
export const DispensaryModal = () => import('../../components/cannahealrx/modals/dispensaryModal.vue' /* webpackChunkName: "components/dispensary-modal" */).then(c => wrapFunctional(c.default || c))
export const EditCannaAppointmentModal = () => import('../../components/cannahealrx/modals/editCannaAppointmentModal.vue' /* webpackChunkName: "components/edit-canna-appointment-modal" */).then(c => wrapFunctional(c.default || c))
export const NoteTemplateModal = () => import('../../components/cannahealrx/modals/noteTemplateModal.vue' /* webpackChunkName: "components/note-template-modal" */).then(c => wrapFunctional(c.default || c))
export const ProcessingPaymentModal = () => import('../../components/cannahealrx/modals/processingPaymentModal.vue' /* webpackChunkName: "components/processing-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const ReferralCouponModal = () => import('../../components/cannahealrx/modals/referralCouponModal.vue' /* webpackChunkName: "components/referral-coupon-modal" */).then(c => wrapFunctional(c.default || c))
export const ServicesModal = () => import('../../components/cannahealrx/modals/servicesModal.vue' /* webpackChunkName: "components/services-modal" */).then(c => wrapFunctional(c.default || c))
export const StateModal = () => import('../../components/cannahealrx/modals/stateModal.vue' /* webpackChunkName: "components/state-modal" */).then(c => wrapFunctional(c.default || c))
export const TwoFactorAuthModal = () => import('../../components/cannahealrx/modals/twoFactorAuthModal.vue' /* webpackChunkName: "components/two-factor-auth-modal" */).then(c => wrapFunctional(c.default || c))
export const TwoFactorSuccess = () => import('../../components/cannahealrx/modals/twoFactorSuccess.vue' /* webpackChunkName: "components/two-factor-success" */).then(c => wrapFunctional(c.default || c))
export const CannaNotesContainer = () => import('../../components/cannahealrx/notes/cannaNotesContainer.vue' /* webpackChunkName: "components/canna-notes-container" */).then(c => wrapFunctional(c.default || c))
export const NoteTemplateList = () => import('../../components/cannahealrx/notes/noteTemplateList.vue' /* webpackChunkName: "components/note-template-list" */).then(c => wrapFunctional(c.default || c))
export const CannaPatientDetails = () => import('../../components/cannahealrx/patientDetails/cannaPatientDetails.vue' /* webpackChunkName: "components/canna-patient-details" */).then(c => wrapFunctional(c.default || c))
export const PatientDetailsNotes = () => import('../../components/cannahealrx/patientDetails/patientDetailsNotes.vue' /* webpackChunkName: "components/patient-details-notes" */).then(c => wrapFunctional(c.default || c))
export const CannaTableFilter = () => import('../../components/cannahealrx/patients/cannaTableFilter.vue' /* webpackChunkName: "components/canna-table-filter" */).then(c => wrapFunctional(c.default || c))
export const TaskCard = () => import('../../components/care-plan/calendar/TaskCard.vue' /* webpackChunkName: "components/task-card" */).then(c => wrapFunctional(c.default || c))
export const TaskCardTrack = () => import('../../components/care-plan/calendar/TaskCardTrack.vue' /* webpackChunkName: "components/task-card-track" */).then(c => wrapFunctional(c.default || c))
export const AppointmentBox = () => import('../../components/communications/appointments/AppointmentBox.vue' /* webpackChunkName: "components/appointment-box" */).then(c => wrapFunctional(c.default || c))
export const ChatBox = () => import('../../components/communications/chat/chat-box.vue' /* webpackChunkName: "components/chat-box" */).then(c => wrapFunctional(c.default || c))
export const PhoneCallContainer = () => import('../../components/communications/phone/phone-call-container.vue' /* webpackChunkName: "components/phone-call-container" */).then(c => wrapFunctional(c.default || c))
export const EditDocumentModal = () => import('../../components/documents/modals/editDocumentModal.vue' /* webpackChunkName: "components/edit-document-modal" */).then(c => wrapFunctional(c.default || c))
export const CarePlanMedications = () => import('../../components/medications/care-plan/CarePlanMedications.vue' /* webpackChunkName: "components/care-plan-medications" */).then(c => wrapFunctional(c.default || c))
export const CarePlanMedicationsModal = () => import('../../components/medications/care-plan/CarePlanMedicationsModal.vue' /* webpackChunkName: "components/care-plan-medications-modal" */).then(c => wrapFunctional(c.default || c))
export const AssessmentIcon = () => import('../../components/notes/modals/assessmentIcon.vue' /* webpackChunkName: "components/assessment-icon" */).then(c => wrapFunctional(c.default || c))
export const ConfirmRestoreModal = () => import('../../components/notes/modals/confirmRestoreModal.vue' /* webpackChunkName: "components/confirm-restore-modal" */).then(c => wrapFunctional(c.default || c))
export const DraftIcon = () => import('../../components/notes/modals/draftIcon.vue' /* webpackChunkName: "components/draft-icon" */).then(c => wrapFunctional(c.default || c))
export const ExpandedNotesModal = () => import('../../components/notes/modals/expandedNotesModal.vue' /* webpackChunkName: "components/expanded-notes-modal" */).then(c => wrapFunctional(c.default || c))
export const NoteDrawerModal = () => import('../../components/notes/modals/noteDrawerModal.vue' /* webpackChunkName: "components/note-drawer-modal" */).then(c => wrapFunctional(c.default || c))
export const NoteHistoryModal = () => import('../../components/notes/modals/noteHistoryModal.vue' /* webpackChunkName: "components/note-history-modal" */).then(c => wrapFunctional(c.default || c))
export const NotesDeleteModal = () => import('../../components/notes/modals/notesDeleteModal.vue' /* webpackChunkName: "components/notes-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisAddCareNote = () => import('../../components/oasis/modals/oasisAddCareNote.vue' /* webpackChunkName: "components/oasis-add-care-note" */).then(c => wrapFunctional(c.default || c))
export const OasisAddLabVital = () => import('../../components/oasis/modals/oasisAddLabVital.vue' /* webpackChunkName: "components/oasis-add-lab-vital" */).then(c => wrapFunctional(c.default || c))
export const OasisAddNewVital = () => import('../../components/oasis/modals/oasisAddNewVital.vue' /* webpackChunkName: "components/oasis-add-new-vital" */).then(c => wrapFunctional(c.default || c))
export const OasisAllergiesModal = () => import('../../components/oasis/modals/oasisAllergiesModal.vue' /* webpackChunkName: "components/oasis-allergies-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisAssignPatientModal = () => import('../../components/oasis/modals/oasisAssignPatientModal.vue' /* webpackChunkName: "components/oasis-assign-patient-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisBulkModal = () => import('../../components/oasis/modals/oasisBulkModal.vue' /* webpackChunkName: "components/oasis-bulk-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisEditAddressModal = () => import('../../components/oasis/modals/oasisEditAddressModal.vue' /* webpackChunkName: "components/oasis-edit-address-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisMedicationsModal = () => import('../../components/oasis/modals/oasisMedicationsModal.vue' /* webpackChunkName: "components/oasis-medications-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisMedicsDetails = () => import('../../components/oasis/modals/oasisMedicsDetails.vue' /* webpackChunkName: "components/oasis-medics-details" */).then(c => wrapFunctional(c.default || c))
export const OasisNoteModal = () => import('../../components/oasis/modals/oasisNoteModal.vue' /* webpackChunkName: "components/oasis-note-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisOnboardPatientModal = () => import('../../components/oasis/modals/oasisOnboardPatientModal.vue' /* webpackChunkName: "components/oasis-onboard-patient-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisPharmacyModal = () => import('../../components/oasis/modals/oasisPharmacyModal.vue' /* webpackChunkName: "components/oasis-pharmacy-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisProvidersModal = () => import('../../components/oasis/modals/oasisProvidersModal.vue' /* webpackChunkName: "components/oasis-providers-modal" */).then(c => wrapFunctional(c.default || c))
export const OasisRXDetails = () => import('../../components/oasis/modals/oasisRXDetails.vue' /* webpackChunkName: "components/oasis-r-x-details" */).then(c => wrapFunctional(c.default || c))
export const OasisVaccinationModal = () => import('../../components/oasis/modals/oasisVaccinationModal.vue' /* webpackChunkName: "components/oasis-vaccination-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterModalIcon = () => import('../../components/patientFilters/svg/filterModalIcon.vue' /* webpackChunkName: "components/filter-modal-icon" */).then(c => wrapFunctional(c.default || c))
export const AmdiabetesLogo = () => import('../../components/rpm-reports/logos/amdiabetesLogo.vue' /* webpackChunkName: "components/amdiabetes-logo" */).then(c => wrapFunctional(c.default || c))
export const DefaultVianovaLogo = () => import('../../components/rpm-reports/logos/defaultVianovaLogo.vue' /* webpackChunkName: "components/default-vianova-logo" */).then(c => wrapFunctional(c.default || c))
export const HcviLogo = () => import('../../components/rpm-reports/logos/hcviLogo.vue' /* webpackChunkName: "components/hcvi-logo" */).then(c => wrapFunctional(c.default || c))
export const SayfLogo = () => import('../../components/rpm-reports/logos/sayfLogo.vue' /* webpackChunkName: "components/sayf-logo" */).then(c => wrapFunctional(c.default || c))
export const EditTimerModal = () => import('../../components/timers/modals/editTimerModal.vue' /* webpackChunkName: "components/edit-timer-modal" */).then(c => wrapFunctional(c.default || c))
export const TimerInactiveModal = () => import('../../components/timers/modals/timerInactiveModal.vue' /* webpackChunkName: "components/timer-inactive-modal" */).then(c => wrapFunctional(c.default || c))
export const TimerInfoModal = () => import('../../components/timers/modals/timerInfoModal.vue' /* webpackChunkName: "components/timer-info-modal" */).then(c => wrapFunctional(c.default || c))
export const TimerStopSuccessModal = () => import('../../components/timers/modals/timerStopSuccessModal.vue' /* webpackChunkName: "components/timer-stop-success-modal" */).then(c => wrapFunctional(c.default || c))
export const TopBarModal = () => import('../../components/top-bar/modal/TopBarModal.vue' /* webpackChunkName: "components/top-bar-modal" */).then(c => wrapFunctional(c.default || c))
export const BloodPressureChart = () => import('../../components/vitals-measurement/chart/BloodPressureChart.vue' /* webpackChunkName: "components/blood-pressure-chart" */).then(c => wrapFunctional(c.default || c))
export const PainScaleChart = () => import('../../components/vitals-measurement/chart/PainScaleChart.vue' /* webpackChunkName: "components/pain-scale-chart" */).then(c => wrapFunctional(c.default || c))
export const VitalChart = () => import('../../components/vitals-measurement/chart/VitalChart.vue' /* webpackChunkName: "components/vital-chart" */).then(c => wrapFunctional(c.default || c))
export const MeasurementTable = () => import('../../components/vitals-measurement/measurement-table/MeasurementTable.vue' /* webpackChunkName: "components/measurement-table" */).then(c => wrapFunctional(c.default || c))
export const LabVitalFilterSelect = () => import('../../components/vitals-measurement/measurement-table/labVitalFilterSelect.vue' /* webpackChunkName: "components/lab-vital-filter-select" */).then(c => wrapFunctional(c.default || c))
export const EditArticleModal = () => import('../../components/admin/library/articles/editArticleModal.vue' /* webpackChunkName: "components/edit-article-modal" */).then(c => wrapFunctional(c.default || c))
export const EditTopicModal = () => import('../../components/admin/library/topics/editTopicModal.vue' /* webpackChunkName: "components/edit-topic-modal" */).then(c => wrapFunctional(c.default || c))
export const ProceedConfirmationModal = () => import('../../components/admin/users/exist-user/ProceedConfirmationModal.vue' /* webpackChunkName: "components/proceed-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const AppointmentModal = () => import('../../components/care-plan/calendar/modals/AppointmentModal.vue' /* webpackChunkName: "components/appointment-modal" */).then(c => wrapFunctional(c.default || c))
export const ConversationModal = () => import('../../components/care-plan/calendar/modals/ConversationModal.vue' /* webpackChunkName: "components/conversation-modal" */).then(c => wrapFunctional(c.default || c))
export const DeleteModal = () => import('../../components/care-plan/calendar/modals/DeleteModal.vue' /* webpackChunkName: "components/delete-modal" */).then(c => wrapFunctional(c.default || c))
export const EducationModal = () => import('../../components/care-plan/calendar/modals/EducationModal.vue' /* webpackChunkName: "components/education-modal" */).then(c => wrapFunctional(c.default || c))
export const ManageParticipantModal = () => import('../../components/care-plan/calendar/modals/ManageParticipantModal.vue' /* webpackChunkName: "components/manage-participant-modal" */).then(c => wrapFunctional(c.default || c))
export const MedicationModal = () => import('../../components/care-plan/calendar/modals/MedicationModal.vue' /* webpackChunkName: "components/medication-modal" */).then(c => wrapFunctional(c.default || c))
export const SequenceModal = () => import('../../components/care-plan/calendar/modals/SequenceModal.vue' /* webpackChunkName: "components/sequence-modal" */).then(c => wrapFunctional(c.default || c))
export const TaskModal = () => import('../../components/care-plan/calendar/modals/TaskModal.vue' /* webpackChunkName: "components/task-modal" */).then(c => wrapFunctional(c.default || c))
export const VitalModal = () => import('../../components/care-plan/calendar/modals/VitalModal.vue' /* webpackChunkName: "components/vital-modal" */).then(c => wrapFunctional(c.default || c))
export const ConvoFlowModal = () => import('../../components/care-plan/calendar/modals/convoFlowModal.vue' /* webpackChunkName: "components/convo-flow-modal" */).then(c => wrapFunctional(c.default || c))
export const VitalsModal = () => import('../../components/vitals-measurement/measurement-table/item-modal/VitalsModal.vue' /* webpackChunkName: "components/vitals-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
